import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { useParams } from "react-router-dom";
import {
  getDivisions,
  getDivisionScheduleById,
} from "../../../app/venueMasterSlice";
import ReactQuill from "react-quill";
import { Team } from "@/src/types/types";
import CheckBox from "../../UI/Checkbox/Checkbox";
import Button from "../../UI/Button/Button";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import Body1 from "../../UI/Text/Body/Body1";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_TYPES,
  GET_EMAIL_TEMPLATES,
  UPDATE_EMAIL_TEMPLATE,
} from "../../../graphql/queries/email";
import { header } from "./Headers";
import { footer } from "./Footers";
import { emailButton } from "./EmailButton";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { GET_DIVISIONS } from "../../../graphql/queries/divisions";
import { LoaderCircle } from "lucide-react";
import FormField from "../../UI/FormField/FormField";
import Caption1 from "../../UI/Text/Caption/Caption1";
import Headline2 from "../../UI/Text/Headline/Headline2";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import { useToast } from "../../UI/Toast/use-toast";
import FormFieldViewOnly from "../../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../UI/shadcn/dialog";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";

type EmailTemplate = {
  id?: number;
  subject: string;
  name: string;
  body: string;
  updatedBy?: string;
  updatedAt?: Date;
  typeId: number;
};

type EmailTemplateType = {
  id: number;
  name: string;
};

type EmailTemplateVariable = {
  id: number;
  name: string;
  regex: RegExp;
  validTemplateTypes: number[];
};

const initialEmailState = {
  subject: "",
  body: "<p></br><p>",
  name: "",
  typeId: 0,
};

const EmailTemplateManager = () => {
  const { isLoading }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const dispatch = useDispatch<AppDispatch>();

  const [email, setEmail] = useState<EmailTemplate>(initialEmailState);
  const [allEmails, setAllEmails] = useState<EmailTemplate[]>([]);
  const [invalidEmailVars, setInvalidEmailVars] = useState<string[]>([]);
  const [validVariables, setValidVariables] = useState<EmailTemplateVariable[]>(
    [
      {
        id: 1,
        name: "sportRegex",
        regex: /%%sport%%/g,
        validTemplateTypes: [1],
      },
      {
        id: 2,
        name: "dayOfWeekRegex",
        validTemplateTypes: [1],
        regex: /%%day_of_week%%/g,
      },
      {
        id: 3,
        name: "teamNameRegex",
        validTemplateTypes: [1],
        regex: /%%team_name%%/g,
      },
      {
        id: 4,
        name: "firstNameRegex",
        validTemplateTypes: [1, 2],
        regex: /%%first_name%%/g,
      },
      {
        id: 5,
        name: "buttonRegex",
        regex:
          /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
        validTemplateTypes: [1, 2],
      },
    ]
  );

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  function validateBody() {
    // Find all instances of text that don't match any of the regex patterns
    const regex = /%%[^%]+%%/g;
    const matchesBody = email.body.match(regex);
    const matchesSubject = email.subject.match(regex);
    let invalidMatches: string[] = [];
    if (matchesBody) {
      invalidMatches = matchesBody.filter((match: string) => {
        return !validVariables.some(
          (validReg) =>
            match.match(validReg.regex) &&
            validReg.validTemplateTypes.includes(email.typeId)
        );
      });
    }
    if (matchesSubject) {
      invalidMatches.push(
        ...matchesSubject.filter((match: string) => {
          return !validVariables.some(
            (validReg) =>
              validReg.validTemplateTypes.includes(email.typeId) &&
              match.match(validReg.regex)
          );
        })
      );
    }
    // If there are any invalid matches return without sending
    if (invalidMatches.length > 0) {
      console.log("invalid vars");
      setInvalidEmailVars(invalidMatches);
      return false;
    }
    return true;
  }

  const { data, loading } = useQuery(GET_EMAIL_TEMPLATES);

  const { data: templateTypeData, loading: loadingTemplateType } = useQuery(
    GET_EMAIL_TEMPLATE_TYPES
  );

  const [createEmailTemplate, { loading: loadingCreate }] = useMutation(
    CREATE_EMAIL_TEMPLATE,
    {
      variables: {
        emailTemplateInput: {
          name: email?.name,
          subject: email?.subject,
          body: email?.body,
          typeId: +email?.typeId,
        },
      },
      refetchQueries: [GET_EMAIL_TEMPLATES, "getEmailTemplates"],
      onCompleted: (data) => {
        if (data.createEmailTemplate) {
          setEmail(data.createEmailTemplate.emailTemplate);
        }
        if (data.createEmailTemplate.message.success) {
          dispatch(
            displayAlertSuccess(data.createEmailTemplate.message.message)
          );
        } else {
          dispatch(displayAlertError(data.createEmailTemplate.message.message));
        }
      },
      onError: (err) => {
        dispatch(displayAlertError("Something went wrong."));
      },
    }
  );

  const [updateEmailTemplate, { loading: loadingUpdate }] = useMutation(
    UPDATE_EMAIL_TEMPLATE,
    {
      variables: {
        emailTemplateInput: {
          id: email?.id,
          name: email?.name,
          subject: email?.subject,
          body: email?.body,
          typeId: +email?.typeId,
        },
      },
      refetchQueries: [GET_EMAIL_TEMPLATES, "getEmailTemplates"],
      onCompleted: (data) => {
        if (data.updateEmailTemplate.success) {
          dispatch(displayAlertSuccess(data.updateEmailTemplate.message));
        } else {
          dispatch(displayAlertError(data.updateEmailTemplate.message));
        }
      },
      onError: (err) => {
        dispatch(displayAlertError("Something went wrong."));
      },
    }
  );
  useEffect(() => {
    if (iframeRef.current && email !== undefined) {
      const formattedBody = `${header}${email.body.replace(
        /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
        (_, link, text) => {
          return emailButton(link, text);
        }
      )}${footer}`;
      const blob = new Blob([formattedBody], { type: "text/html" });
      iframeRef.current.src = URL.createObjectURL(blob);
    }
  }, [email]);

  useEffect(() => {
    if (data && data.getEmailTemplates) {
      setAllEmails(data.getEmailTemplates);
    }
  }, [data]);

  if (
    isLoading ||
    loading ||
    loadingCreate ||
    loadingUpdate ||
    loadingTemplateType
  ) {
    return <LoadingDialog open={true} />;
  }

  return (
    <main className="flex flex-col gap-4">
      <h2>Email Template Manager</h2>
      <div className="flex flex-col">
        <div className="flex flex-row items-end justify-start gap-4">
          <div className="w-80 max-w-80">
            <FormFieldSelect
              name="templateSelect"
              value={email?.id?.toString() ?? "0"}
              inputChange={(value) => {
                const newEmail = allEmails.find(
                  (template: EmailTemplate) => template.id === +value
                );
                if (newEmail) {
                  setEmail(newEmail);
                }
              }}
              label="Template"
              placeholder="Select Email Temaplte"
            >
              {[
                { id: 0, name: "Select a Template" },
                ...data.getEmailTemplates.map((template: EmailTemplate) => {
                  return { id: template.id, name: template.name };
                }),
              ]}
            </FormFieldSelect>
          </div>
          <div className="whitespace-nowrap">
            <Button
              variant="primary"
              onClick={() => {
                setEmail({
                  name: "",
                  subject: "",
                  body: "<p>New Template</p>",
                  typeId: 0,
                });
              }}
            >
              New Template
            </Button>
          </div>
        </div>
      </div>

      {email !== undefined && email !== initialEmailState && (
        <div className="flex flex-row justify-between w-full gap-4">
          <div className="flex flex-col w-1/2 gap-4">
            <div className="flex flex-col gap-2">
              {email.id ? (
                <FormFieldViewOnly
                  label="Type"
                  text={
                    templateTypeData.getEmailTemplateTypes.find(
                      (type: EmailTemplateType) => type.id === email.typeId
                    ).name ?? "Unknown"
                  }
                />
              ) : (
                <FormFieldSelect
                  name="templateSelect"
                  value={email?.typeId?.toString() ?? "0"}
                  inputChange={(value) => {
                    setEmail((prev) => ({
                      ...prev,
                      typeId: value,
                    }));
                  }}
                  label="Type"
                  placeholder="Select Template Type"
                >
                  {[
                    { id: 0, name: "Select Template Type" },
                    ...templateTypeData.getEmailTemplateTypes.map(
                      (type: EmailTemplateType) => {
                        return { id: type.id, name: type.name };
                      }
                    ),
                  ]}
                </FormFieldSelect>
              )}
              <FormFieldControlled
                onChange={(value) => {
                  setEmail((prev) => ({
                    ...prev,
                    name: value.target.value,
                  }));
                }}
                value={email.name}
                label="Name"
              />
              <FormFieldControlled
                onChange={(value) => {
                  setEmail((prev) => ({
                    ...prev,
                    subject: value.target.value,
                  }));
                }}
                value={email.subject}
                label="Subject"
              />
            </div>

            <div className="mb-[46px]">
              <div className="flex flex-col">
                <Caption1 className="px-3">Body</Caption1>
                <ReactQuill
                  theme="snow"
                  value={email.body}
                  onChange={(text: string) =>
                    setEmail((prev) => ({
                      ...prev,
                      body: text,
                    }))
                  }
                  style={{ height: "400px", width: "100%" }}
                />
              </div>
            </div>
            <div>
              <Button
                variant="primary"
                onClick={() => {
                  if (validateBody()) {
                    email.id === undefined
                      ? createEmailTemplate()
                      : updateEmailTemplate();
                  }
                }}
              >
                {email.id === undefined ? "Create" : "Update"}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  validateBody();
                }}
              >
                Test
              </Button>
            </div>
            <Dialog
              open={invalidEmailVars.length > 0}
              onOpenChange={() => setInvalidEmailVars([])}
            >
              <DialogContent className="max-w-96">
                <DialogHeader>
                  <DialogTitle>Refund Notes</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                  <div className="flex flex-col gap-4">
                    <Body1>
                      This email template contains the following invalid
                      variables
                    </Body1>
                    {invalidEmailVars.map((invalidVars) => {
                      return <Body1>{invalidVars}</Body1>;
                    })}
                  </div>
                </DialogDescription>
                <DialogClose>
                  <Button
                    variant={"negative"}
                    onClick={() => setInvalidEmailVars([])}
                  >
                    Close
                  </Button>
                </DialogClose>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex flex-col w-1/2 gap-4">
            <div className="flex flex-col">
              <Headline2Variable>
                Email Variables (click to copy)
              </Headline2Variable>
              <div className="flex flex-col">
                {[1, 3].includes(+email.typeId) && (
                  <>
                    <div className="flex flex-row gap-2">
                      <Body1 className="w-32">Day of week:</Body1>
                      <IconButton
                        sx={{
                          "&:hover": { color: "var(--primary-80)" },
                          height: "24px",
                          width: "24px",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText("%%day_of_week%%");
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      <Body1 className="bg-info-90 w-fit">
                        %%day_of_week%%
                      </Body1>
                    </div>
                    <div className="flex flex-row gap-2">
                      <Body1 className="w-32">Sport:</Body1>
                      <IconButton
                        sx={{
                          "&:hover": { color: "var(--primary-80)" },
                          height: "24px",
                          width: "24px",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText("%%sport%%");
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      <Body1 className=" bg-info-90 w-fit">%%sport%%</Body1>
                    </div>
                    <div className="flex flex-row gap-2">
                      <Body1 className="w-32">Team Name:</Body1>
                      <IconButton
                        sx={{
                          "&:hover": { color: "var(--primary-80)" },
                          height: "24px",
                          width: "24px",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText("%%team_name%%");
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      <Body1 className=" bg-info-90 w-fit">%%team_name%%</Body1>
                    </div>
                  </>
                )}
                <div className="flex flex-row gap-2">
                  <Body1 className="w-32">First Name:</Body1>
                  <IconButton
                    sx={{
                      "&:hover": { color: "var(--primary-80)" },
                      height: "24px",
                      width: "24px",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText("%%first_name%%");
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                  <Body1 className=" bg-info-90 w-fit">%%first_name%%</Body1>
                </div>

                <div className="flex flex-row gap-2">
                  <Body1 className="w-32">Button:</Body1>
                  <IconButton
                    sx={{
                      "&:hover": { color: "var(--primary-80)" },
                      height: "24px",
                      width: "24px",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText("%%button_link_text%%");
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                  <Body1 className="bg-info-90 w-fit">
                    %%button_link_text%%
                  </Body1>
                  <Body1>%%button_https://jamsports.com_Home%%</Body1>
                </div>
              </div>
            </div>
            <div className="max-w-[600px]">
              <iframe
                ref={iframeRef}
                title="Email Preview"
                style={{ width: "100%", height: "600px", border: "none" }}
              />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default EmailTemplateManager;
