import React from "react";
import ReactDOM from "react-dom/client";
import "normalize.css";
import "./index.css";
import App from "./App";
import { RootState, store } from "./app/store";
import { Provider } from "react-redux";
import { injectStore } from "./app/authSlice";
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql";
import { Toaster } from "./components/UI/Toast/toaster";
import { ToasterStackable } from "./components/UI/shadcn/sonner";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { CircleCheck, CircleX, Info, TriangleAlert } from "lucide-react";
import { GlobalContextProvider } from "./context/GlobalContext";
import { EmailMarketingFilterSchema } from "./components/Marketing/Email/EmailToolMarketingFilter";
import { EmailToolMarketingContextProvider } from "./context/EmailToolMarketingContext";
import { ImageManagerContextProvider } from "./context/ImageMangerContext";

injectStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GlobalContextProvider>
    <EmailToolMarketingContextProvider>
      <ImageManagerContextProvider>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <App />
            <Toaster />
            <ToasterStackable
              toastOptions={{
                classNames: {
                  description: "text-black",
                  title: "text-black font-semibold",
                  info: "bg-info-95",
                  success: "bg-success-95",
                  warning: "bg-warning-95",
                  error: "bg-error-95",
                },
              }}
              icons={{
                success: <CircleCheck className="w-5 h-5 text-green-700" />,
                info: <Info className="w-5 h-5 text-blue-700" />,
                warning: <TriangleAlert className="w-5 h-5 text-orange-400" />,
                error: <CircleX className="w-5 h-5 text-red-500" />,
              }}
              position="top-center"
              duration={6000}
            />
          </ApolloProvider>
        </Provider>
      </ImageManagerContextProvider>
    </EmailToolMarketingContextProvider>
  </GlobalContextProvider>
);
