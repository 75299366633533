import { FC, useEffect, useState } from "react";
import { File, Folder, Tree, TreeViewElement } from "../magic-ui/file-tree";
import { useImageManagerContext } from "../../../context/ImageMangerContext";
import {
  ImageManagerDirectory,
  ListAllOperations,
  useCreateImageManagerDirectoryMutation,
  useGetAllImageManagerDirectoryLazyQuery,
} from "../../../generated/graphql";
import { PlusIcon } from "lucide-react";
import Button from "../Button/Button";
import Body1 from "../Text/Body/Body1";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../shadcn/dialog";
import FormFieldControlled from "../FormField/FormFieldControlled";
import LoadingDialog from "../Dialog/LoadingDialog";

interface FileTreeProps {
  /**
   * A list of elements for the tree to display
   */
  elements?: TreeViewElement;
}

const FileTree: FC<FileTreeProps> = ({ elements }) => {
  const {
    selectedDirectoryId,
    setSelectedDirectoryId,
    setSelectedDirectoryPath,
    selectedDirectoryImages,
  } = useImageManagerContext();
  const [newFolderName, setNewFolderName] = useState<string>("");
  const [imageManagerDirectoryTree, setImageManagerDirectoryTree] = useState<
    TreeViewElement[]
  >([]);
  const [imageManagerDirectoryData, setImageManagerDirectoryData] = useState<
    ImageManagerDirectory[]
  >([]);

  /*** QUERIES ***/
  const [GetImageManagerDirectory, { loading: loadingImageManagerDirectory }] =
    useGetAllImageManagerDirectoryLazyQuery({
      onCompleted: (data) => {
        const treeData = buildTreeView(data.getAllImageManagerDirectory);
        setImageManagerDirectoryData(data.getAllImageManagerDirectory);
        setImageManagerDirectoryTree(treeData);
      },
      notifyOnNetworkStatusChange: true,
    });

  /*** MUTATIONS ***/
  const [
    CreateImageManagerDirectory,
    { loading: loadingCreateImageManagerDirectory },
  ] = useCreateImageManagerDirectoryMutation();

  async function handleSubmitNewFolder() {
    if (selectedDirectoryId) {
      CreateImageManagerDirectory({
        variables: {
          createImageManagerDirectoryInput: {
            parentId: selectedDirectoryId.id,
            name: newFolderName.replace(/\s/g, "_"),
          },
        },
        refetchQueries: [ListAllOperations.Query.GetAllImageManagerDirectory],
      });
    }
  }

  function buildTreeView(
    data: { id: number; parentId: number; name: string }[]
  ): TreeViewElement[] {
    const idToNodeMap: { [key: number]: TreeViewElement } = {};
    const rootNodes: TreeViewElement[] = [];
    // Create the initial node structure
    data.forEach((item) => {
      idToNodeMap[item.id] = {
        id: item.id.toString(),
        name: item.name,
        isSelectable: true,
        children: [],
      };
    });

    // Build the tree by associating children with their parents
    data.forEach((item) => {
      const node = idToNodeMap[item.id];
      if (item.parentId === 0) {
        rootNodes.push(node); // It's a root node
      } else {
        idToNodeMap[item.parentId].children!.push(node); // Add to parent's children
      }
    });

    return rootNodes;
  }

  function getFullPath(data: ImageManagerDirectory[], id: number): string {
    const idToNodeMap: { [key: number]: ImageManagerDirectory } = {};

    // Map all items by their id for quick lookup
    data.forEach((item) => {
      idToNodeMap[item.id] = item;
    });

    // Recursive function to build the path
    function buildPath(nodeId: number): string {
      const node = idToNodeMap[nodeId];
      if (!node || node.parentId === 0) {
        return node ? node.name : ""; // Return the name of the root node
      }
      return `${buildPath(node.parentId)}/${node.name}`; // Recursively prepend the parent's path
    }

    return buildPath(id);
  }

  const renderTree = (elements: TreeViewElement[]) => {
    return elements.map((element) => {
      if (element.children) {
        return (
          <Folder
            key={element.id}
            value={element.id}
            element={element.name}
            isSelect={+element.id === selectedDirectoryId?.id}
            handleFolderClicked={({ id, name }) => {
              setSelectedDirectoryId({ id: id, name: name });
              console.log(selectedDirectoryImages);
              console.log(imageManagerDirectoryData);
              setSelectedDirectoryPath(
                getFullPath(imageManagerDirectoryData, id)
              );
            }}
          >
            {renderTree(element.children)}
          </Folder>
        );
      } else {
        return (
          <File
            key={element.id}
            value={element.id}
          >
            <p>{element.name}</p>
          </File>
        );
      }
    });
  };

  /*** USE EFFECTS ***/
  useEffect(() => {
    GetImageManagerDirectory();
  }, []);

  return (
    <div className="relative flex flex-row h-[300px] min-w-fit w-1/2 items-start justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl">
      <LoadingDialog open={loadingCreateImageManagerDirectory} />
      {imageManagerDirectoryTree && (
        <Tree
          className="p-2 overflow-hidden rounded-md bg-background"
          elements={imageManagerDirectoryTree}
        >
          {renderTree(imageManagerDirectoryTree)}
        </Tree>
      )}
      <Dialog>
        <DialogTrigger>
          <Button
            variant={
              selectedDirectoryId === undefined ? "disabled" : "secondary"
            }
            className="mt-2 mr-2"
            disabled={selectedDirectoryId === undefined}
          >
            <div className="flex flex-row gap-1">
              <PlusIcon />

              <Body1 className="whitespace-nowrap">New Folder</Body1>
            </div>
          </Button>
        </DialogTrigger>
        <DialogContent className="max-w-96">
          <DialogHeader>
            <DialogTitle>Delete Image</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <div className="flex flex-col gap-4">
              <Body1>Enter a name for the new folder</Body1>
              {selectedDirectoryId && (
                <Body1>Parent folder: {selectedDirectoryId.name}</Body1>
              )}
              <FormFieldControlled
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewFolderName(event.target.value);
                }}
                value={newFolderName}
                label=""
              />
            </div>
          </DialogDescription>
          <DialogFooter>
            <DialogClose>
              <Button
                variant="primary"
                className="mt-2 mr-2"
                onClick={handleSubmitNewFolder}
              >
                <div className="flex flex-row gap-1">
                  <Body1 className="whitespace-nowrap">Confirm</Body1>
                </div>
              </Button>
            </DialogClose>
            <DialogClose>
              <Button
                variant="secondary"
                className="mt-2 mr-2"
              >
                <div className="flex flex-row gap-1">
                  <Body1 className="whitespace-nowrap">Cancel</Body1>
                </div>
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FileTree;
