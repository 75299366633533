import { createContext, useContext, useState } from "react";
import { GetImagesPaginatedQuery } from "../generated/graphql";

type ImageManagerContextType = {
  selectedDirectoryId: { id: number; name: string } | undefined;
  setSelectedDirectoryId: React.Dispatch<
    React.SetStateAction<{ id: number; name: string } | undefined>
  >;
  selectedDirectoryPath: string;
  setSelectedDirectoryPath: React.Dispatch<React.SetStateAction<string>>;
  selectedDirectoryImages: GetImagesPaginatedQuery["getImagesPaginated"]["images"];
  setSelectedDirectoryImages: React.Dispatch<
    React.SetStateAction<
      GetImagesPaginatedQuery["getImagesPaginated"]["images"]
    >
  >;
};

const ImageManagerContextDefault: ImageManagerContextType = {
  selectedDirectoryId: undefined,
  setSelectedDirectoryId: () => {},
  selectedDirectoryPath: "",
  setSelectedDirectoryPath: () => {},
  selectedDirectoryImages: [],
  setSelectedDirectoryImages: () => {},
};

const ImageManagerContext = createContext<ImageManagerContextType>(
  ImageManagerContextDefault
);

export const ImageManagerContextProvider = ({ children }: any) => {
  const [selectedDirectoryId, setSelectedDirectoryId] = useState<
    { id: number; name: string } | undefined
  >(undefined);
  const [selectedDirectoryPath, setSelectedDirectoryPath] =
    useState<string>("");
  const [selectedDirectoryImages, setSelectedDirectoryImages] = useState<
    GetImagesPaginatedQuery["getImagesPaginated"]["images"]
  >([]);

  const value = {
    selectedDirectoryId,
    setSelectedDirectoryId,
    selectedDirectoryPath,
    setSelectedDirectoryPath,
    selectedDirectoryImages,
    setSelectedDirectoryImages,
  };

  return (
    <ImageManagerContext.Provider value={value}>
      {children}
    </ImageManagerContext.Provider>
  );
};

export const useImageManagerContext = () => useContext(ImageManagerContext);
