import React, { useEffect, useMemo, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/src/app/store";
import { Column } from "react-table";
import ActionsCell from "../UI/ActionCell";
import BaseTable from "../UI/Table/Table";
import TablePagination from "../UI/Pagination/Pagination";
import { Pagination } from "../../types/types";
import { FileUploader } from "react-drag-drop-files";
import { multipartFetch } from "../../app/authSlice";
import {
  GetImagesQuery,
  ListAllOperations,
  useCreateImageMutation,
  useDeleteImageMutation,
  useGetImagesPaginatedLazyQuery,
} from "../../generated/graphql";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../UI/shadcn/dialog";
import Body1 from "../UI/Text/Body/Body1";
import {
  displayAlertError,
  displayAlertInfo,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../app/globalSlice";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import FileTree from "../UI/ImageManager/ImageManagerDirecoryTree";
import { useImageManagerContext } from "../../context/ImageMangerContext";

const fileTypes = ["JPEG", "PNG", "SVG", "JPG"];

// Define the type for the Cell parameter
interface CellParam {
  row: { original: GetImagesQuery["getImages"][0] };
  rows: { original: GetImagesQuery["getImages"][0] }[];
}

const options = [{ id: 1, text: "Delete" }];

const ImageManager: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const pageSize = 10;

  const {
    selectedDirectoryPath,
    selectedDirectoryImages,
    setSelectedDirectoryImages,
  } = useImageManagerContext();
  const [filter, setFilter] = useState<{
    pagination: Pagination;
  }>({
    pagination: {
      page: 0,
      pageCount: 0,
      pageSize: pageSize,
    },
  });
  const [files, setFiles] = useState<FileList | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<
    GetImagesQuery["getImages"][0]
  >({
    id: 0,
    publicUrl: "",
    fileName: "",
  });

  /*** QUERIES ***/
  const [getImages, { loading: loadingImages }] =
    useGetImagesPaginatedLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const pageCount = Math.ceil(data.getImagesPaginated.count / pageSize);
        setSelectedDirectoryImages(data.getImagesPaginated.images);
        setFilter((prevState) => ({
          ...prevState,
          pagination: {
            ...prevState.pagination,
            pageCount: pageCount,
          },
        }));
      },
    });

  /*** MUTATIONS ***/
  const [createImage, { loading: loadingCreateImage }] =
    useCreateImageMutation();

  const [deleteImage, { loading: loadingDeleteImage }] =
    useDeleteImageMutation();

  /*** USE EFFECTS ***/
  useEffect(() => {
    if (filter && selectedDirectoryPath) {
      getImages({
        variables: {
          page: filter.pagination.page,
          pageSize: filter.pagination.pageSize,
          filePath: selectedDirectoryPath,
        },
      });
    }
  }, [filter.pagination.page, selectedDirectoryPath]);

  /*** UTILITY FUNCTIONS ***/
  const handleChangePagination = (value: number) => {
    setFilter((prevState) => ({
      ...prevState,
      pagination: {
        pageCount: prevState.pagination.pageCount,
        pageSize: prevState.pagination.pageSize,
        page: value - 1,
      },
    }));
  };

  const handleUpload = async () => {
    if (!files) return;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
      formData.append(`filePath_${i}`, selectedDirectoryPath); // Add modified file name as a field
    }
    try {
      const response = await multipartFetch.post(
        "/imageManager/upload",
        formData
      );
      if (response.status === 200) {
        dispatch(
          displayAlertWarning(
            `Oops! Duplicate images detected: ${response.data.duplicates.map(
              (duplicateImage: string) => duplicateImage
            )}`
          )
        );
        return;
      }
      const imagesForDb: {
        fileName: string;
        publicUrl: string;
        imageHash: string;
        filePath: string;
      }[] = response.data.results.map(
        (file: {
          fileName: string;
          publicUrl: string;
          hash: string;
          filePath: string;
        }) => {
          return {
            fileName: file.fileName.slice(file.fileName.lastIndexOf(":") + 1),
            publicUrl: file.publicUrl,
            imageHash: file.hash,
            filePath: file.filePath,
          };
        }
      );
      await createImage({
        variables: {
          createImageInput: {
            images: imagesForDb,
          },
        },
        onCompleted: (data) => {
          if (data.createImage.success) {
            dispatch(displayAlertSuccess(data.createImage.message));
          } else {
            dispatch(displayAlertError(data.createImage.message));
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
        refetchQueries: [ListAllOperations.Query.GetImagesPaginated],
      });
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleSelectedOption = (
    row: GetImagesQuery["getImages"][0],
    idx: number
  ) => {
    if (idx === 1) {
      setOpenDeleteDialog(true);
      setSelectedFile(row);
    }
  };

  /*** TABLE UI ***/
  const COLUMNS: Column<GetImagesQuery["getImages"][0]>[] = [
    {
      Header: "Id",
      id: "Id",
      accessor: (d) => {
        return <div>{d.id}</div>;
      },
    },
    {
      Header: "File Name",
      id: "fileName",
      accessor: (d) => {
        return (
          <a
            href={d.publicUrl}
            target="_blank"
          >
            {d.fileName}
          </a>
        );
      },
    },
    {
      Header: "Public Url",
      id: "publicUrl",
      accessor: (d) => {
        return (
          <a
            className="transition-colors hover:bg-secondary-95"
            onClick={() => {
              navigator.clipboard.writeText(d.publicUrl);
              dispatch(displayAlertInfo("Copied To Clipboard!"));
            }}
          >
            {d.publicUrl}
          </a>
        );
      },
    },
    {
      Header: " ",
      Cell: (d: CellParam) => {
        return (
          <ActionsCell
            row={d.row.original}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  return (
    <main>
      <LoadingDialog open={loadingCreateImage || loadingDeleteImage} />
      <Dialog
        open={openDeleteDialog}
        onOpenChange={setOpenDeleteDialog}
      >
        {selectedFile ? (
          <DialogContent className="max-w-96">
            <DialogHeader>
              <DialogTitle>Delete Image</DialogTitle>
            </DialogHeader>
            <DialogDescription>
              <div className="flex flex-col gap-4">
                <Body1>Are you sure you want to delete the image</Body1>
                <Body1>{selectedFile.fileName}</Body1>
              </div>
            </DialogDescription>
            <DialogClose>
              <div className="flex flex-row justify-between w-full gap-4">
                <Button
                  className="w-full"
                  variant="negative"
                  onClick={() => {
                    deleteImage({
                      variables: {
                        id: selectedFile.id,
                      },
                      onCompleted: (data) => {
                        if (data.deleteImage.success) {
                          dispatch(
                            displayAlertSuccess(data.deleteImage.message)
                          );
                        } else {
                          dispatch(displayAlertError(data.deleteImage.message));
                        }
                      },
                      onError: (error) => {
                        dispatch(displayAlertError(error.message));
                      },
                      refetchQueries: [
                        ListAllOperations.Query.GetImagesPaginated,
                      ],
                    });
                  }}
                >
                  Delete
                </Button>
                <Button
                  className="w-full"
                  variant="secondary"
                >
                  Close
                </Button>
              </div>
            </DialogClose>
          </DialogContent>
        ) : (
          <DialogContent className="max-w-96">
            <DialogHeader>
              <DialogTitle>Delete Image Error</DialogTitle>
            </DialogHeader>
            <DialogDescription>
              The file could not be selected
            </DialogDescription>
          </DialogContent>
        )}
      </Dialog>
      <div className="flex flex-row gap-2">
        <FileTree />
        <div className="flex flex-col items-start w-full gap-4">
          <div className="max-w-lg">
            <FileUploader
              multiple={true}
              handleChange={(fileList: FileList) => {
                setFiles(fileList);
              }}
              name="file"
              types={fileTypes}
            />
          </div>
          <Button
            variant={selectedDirectoryPath === "" ? "disabled" : "primary"}
            onClick={() => handleUpload()}
            className="max-w-80"
            disabled={selectedDirectoryPath === ""}
          >
            Submit
          </Button>
        </div>
      </div>
      <div className="flex flex-col justify-between w-full mb-4">
        <Headline1Variable>Image Manager</Headline1Variable>
      </div>
      {selectedDirectoryImages.length > 0 && (
        <BaseTable
          columns={columns}
          data={selectedDirectoryImages}
        />
      )}
      {filter.pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={filter.pagination.page || 0}
            pageCount={filter.pagination.pageCount || 0}
            onChange={handleChangePagination}
          />
        </div>
      )}
    </main>
  );
};

export default ImageManager;
