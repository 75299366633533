import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { useParams } from "react-router-dom";
import {
  getDivisionScheduleById,
  getRegistrationBatch,
  getSports,
} from "../../../app/venueMasterSlice";
import ReactQuill from "react-quill";
import CheckBox from "../../UI/Checkbox/Checkbox";
import Button from "../../UI/Button/Button";
import Body1 from "../../UI/Text/Body/Body1";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_EMAIL_TEMPLATES } from "../../../graphql/queries/email";
import { header } from "./Headers";
import { footer } from "./Footers";
import { emailButton } from "./EmailButton";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { GET_DIVISIONS } from "../../../graphql/queries/divisions";
import { LoaderCircle } from "lucide-react";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { useToast } from "../../UI/Toast/use-toast";
import { z } from "zod";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../UI/shadcn/dialog";
import { GET_NUMBER_OF_PLAYERS_ON_TEAMS } from "../../../graphql/queries/team";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import { Separator } from "../../UI/shadcn/separator";
import { useSendDivisionEmailMutation } from "../../../generated/graphql";

const EmailTemplateSchema = z.object({
  id: z.number().optional(),
  subject: z.string().min(1, "Please enter a valid subject"),
  name: z.string(),
  body: z.string().refine(
    (data) => {
      return data !== "<p><br></p>";
    },
    { message: "Please enter a valid body" }
  ),
});

type EmailTemplate = z.infer<typeof EmailTemplateSchema>;

const emptyEmailTemplate = {
  id: 0,
  subject: "",
  name: "",
  body: "<p><br></p>",
};

const EmailToolDivisions = () => {
  const params = useParams();
  const divisionIdParam = params.id;

  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoading,
    isLoadingSchedule,
    divisionScheduleToEdit: divisionById,
    selectedRegions,
    sports,
    registrationBatches,
  }: any = useSelector((state: RootState) => state.venueMaster);

  const [emailBody, setEmailBody] = useState<EmailTemplate>(emptyEmailTemplate);

  const [selectedTeams, setSelectedTeams] = useState<
    { id: number; name: string }[]
  >([]);
  const [divisionId, setDivisionId] = useState<number>(
    divisionIdParam ? +divisionIdParam : 0
  );
  const [divisions, setDivisions] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [numberOfPlayers, setNumberOfPlayers] = useState<number>(0);
  const [divisionFilters, setDivisionFilters] = useState<any>({});
  const [captainsOnly, setCaptainsOnly] = useState<boolean>(false);

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const { data: dataTemplates, loading: loadingTemplates } = useQuery(
    GET_EMAIL_TEMPLATES,
    {
      variables: {
        typeId: 1,
      },
      onCompleted: (data) => {
        console.log(data);
      },
    }
  );

  const [getNumberOfPlayersOnTeams] = useLazyQuery(
    GET_NUMBER_OF_PLAYERS_ON_TEAMS,
    {
      variables: {
        teamIds: selectedTeams.map((team) => team.id),
      },
      onCompleted: (data) => {
        setNumberOfPlayers(data.getNumberOfPlayersOnTeams);
      },
    }
  );

  const [sendDivisionEmail] = useSendDivisionEmailMutation();
  const [getDivisions, { data, loading, error }] = useLazyQuery(GET_DIVISIONS, {
    variables: {
      divisionFilters: {
        regions: divisionFilters.regions,
        sportId: divisionFilters.sportId,
        registrationBatchId: divisionFilters.regBatchId,
        day: divisionFilters.dayOfWeek === 7 ? null : divisionFilters.dayOfWeek,
      },
      fetchPolicy: "network-only",
    },
  });

  useEffect(() => {
    if (divisionId !== 0) {
      dispatch(getDivisionScheduleById(divisionId));
    }
  }, [divisionId]);

  useEffect(() => {
    dispatch(getSports({}));
    dispatch(getRegistrationBatch({}));
  }, []);

  useEffect(() => {
    if (iframeRef.current && emailBody !== undefined) {
      const formattedBody = `${header}${emailBody.body.replace(
        /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
        (_, link, text) => {
          return emailButton(link, text);
        }
      )}${footer}`;
      const blob = new Blob([formattedBody], { type: "text/html" });
      iframeRef.current.src = URL.createObjectURL(blob);
    }
  }, [emailBody]);

  async function handleSendEmail() {
    if (selectedTeams.length === 0) {
      dispatch(displayAlertError("Please select at least 1 team."));
      setOpenConfirmDialog(false);
      return;
    }
    const result = EmailTemplateSchema.safeParse(emailBody);
    if (result.success) {
      getNumberOfPlayersOnTeams();
      setOpenConfirmDialog(true);
    } else {
      setOpenConfirmDialog(false);
      dispatch(
        displayAlertError(
          `${result.error.issues.map((issue) => issue.message).join(",\n")}`
        )
      );
    }
  }
  useEffect(() => {
    getDivisions().then((res) => {
      if (res.data) {
        setDivisions(res.data.divisions);
      }
    });
  }, [divisionFilters]);

  const handleChange = (name: string, value: number) => {
    if (name === "dayOfWeek")
      setDivisionFilters({ ...divisionFilters, dayOfWeek: value });
    else {
      setDivisionFilters({
        ...divisionFilters,
        [name]: value === 0 ? null : value,
      });
    }
  };

  const handleChangeRegion = (value: any) => {
    const regions = value !== 0 ? [value] : null;
    setDivisionFilters({ ...divisionFilters, regions: regions });
  };

  const sendEmail = async () => {
    if (divisionId) {
      sendDivisionEmail({
        variables: {
          divisionId: +divisionId,
          teamIds: selectedTeams.map((team) => team.id),
          subject: emailBody.subject,
          body: emailBody.body,
          captainsOnly: captainsOnly,
        },
      })
        .then((data) => {
          if (data.data?.sendDivisionEmail) {
            dispatch(displayAlertSuccess(data.data.sendDivisionEmail.message));
          } else {
          }
        })
        .catch((err) => {
          dispatch(displayAlertError(err.message));
        });
    }
  };

  if (isLoading || loading || loadingTemplates) {
    return <LoadingDialog open={true} />;
  }

  if (divisionById === undefined) {
    return <div>No division found</div>;
  }

  return (
    <main className="flex flex-col gap-4">
      <Headline1Variable>Division Emails</Headline1Variable>
      <div className="flex flex-row items-center justify-start w-2/3 gap-2 mr-5">
        <FormFieldSelect
          name="regionId"
          value={divisionFilters.regionId?.toString() || "0"}
          inputChange={(value) => {
            handleChangeRegion(+value);
          }}
          label="Region"
          placeholder="Select Region"
        >
          {[...selectedRegions, ...[{ id: "0", name: "All" }]]}
        </FormFieldSelect>
        <FormFieldSelect
          name="sportId"
          value={divisionFilters.sportId?.toString() || "0"}
          inputChange={(value) => {
            handleChange("sportId", +value);
          }}
          label="Sport"
          placeholder="Select Sport"
        >
          {[...sports, ...[{ id: "0", name: "All" }]]}
        </FormFieldSelect>
        <FormFieldSelect
          name="regBatchId"
          value={divisionFilters.regBatchId?.toString() || "0"}
          inputChange={(value) => {
            handleChange("regBatchId", +value);
          }}
          label="Reg Batch"
          placeholder="Select Reg Batch"
        >
          {[...registrationBatches, ...[{ id: "0", name: "All" }]]}
        </FormFieldSelect>
        <FormFieldSelect
          inputChange={(value: string) => {
            handleChange("dayOfWeek", +value);
          }}
          label="Day Of Week"
          placeholder="Day Of Week"
          value={divisionFilters.dayOfWeek?.toString() || "7"}
        >
          {[
            { id: 0, name: "Sunday" },
            { id: 1, name: "Monday" },
            { id: 2, name: "Tuesday" },
            { id: 3, name: "Wednesday" },
            { id: 4, name: "Thursday" },
            { id: 5, name: "Friday" },
            { id: 6, name: "Saturday" },
            { id: 7, name: "All" },
          ]}
        </FormFieldSelect>
      </div>
      <div className="flex flex-row items-center justify-start gap-2">
        <div className="max-w-80 w-80">
          <FormFieldSelect
            name="templateSelect"
            value={emailBody?.id?.toString() ?? "0"}
            inputChange={(value) => {
              if (value === "0") {
                setEmailBody(emptyEmailTemplate);
              } else {
                const newEmail = dataTemplates.getEmailTemplates.find(
                  (template: EmailTemplate) => template.id === +value
                );
                if (newEmail) {
                  setEmailBody(newEmail);
                }
              }
            }}
            label="Template"
            placeholder="Select Email Temaplte"
          >
            {[
              { id: 0, name: "Select a Template" },
              ...dataTemplates.getEmailTemplates.map(
                (template: EmailTemplate) => {
                  return { id: template.id, name: template.name };
                }
              ),
            ]}
          </FormFieldSelect>
        </div>
        <div className="max-w-80 w-80">
          <FormFieldSelect
            name="divisionSelect"
            value={divisionId?.toString() ?? "0"}
            inputChange={(value) => {
              setDivisionId(+value);
            }}
            label="Division"
            placeholder="Select Division"
          >
            {[{ id: 0, name: "Select a Division" }, ...divisions]}
          </FormFieldSelect>
        </div>
      </div>
      <div className="flex flex-col w-1/2 gap-2">
        <Headline2Variable>Division Teams</Headline2Variable>

        {isLoadingSchedule ? (
          <LoaderCircle className="animate-spin" />
        ) : (
          divisionById &&
          divisionById.teams?.length > 0 && (
            <div className="flex flex-col gap-2">
              <CheckBox
                label="Select All"
                defaultChecked={false}
                checkedState={
                  selectedTeams.length === divisionById.teams.length
                }
                inputChange={(val) => {
                  if (val) {
                    setSelectedTeams(
                      divisionById.teams.map(
                        (team: { id: number; name: string }) => {
                          return { id: team.id, name: team.name };
                        }
                      )
                    );
                  } else {
                    setSelectedTeams([]);
                  }
                }}
                id="select-all"
              />
              <div className="grid justify-between max-w-4xl grid-cols-3 gap-2">
                {divisionById.teams.map(
                  (team: { id: number; name: string }, index: number) => {
                    return (
                      <div key={index}>
                        <CheckBox
                          label={team.name}
                          labelClassName="truncate"
                          defaultChecked={false}
                          checkedState={
                            selectedTeams.find(
                              (teamChecked) => teamChecked.id === team.id
                            )
                              ? true
                              : false
                          }
                          inputChange={(val) => {
                            setSelectedTeams(
                              selectedTeams.find(
                                (teamChecked) => teamChecked.id === team.id
                              )
                                ? selectedTeams.filter(
                                    (teamChecked) => teamChecked.id !== team.id
                                  )
                                : [
                                    ...selectedTeams,
                                    { id: +team.id, name: team.name },
                                  ]
                            );
                          }}
                          id={team.name}
                        />
                      </div>
                    );
                  }
                )}
              </div>
              <Separator />
              <CheckBox
                label="Email Team Captains Only"
                defaultChecked={false}
                checkedState={captainsOnly}
                inputChange={(val) => {
                  setCaptainsOnly(val);
                }}
                id="captains-only"
              />
            </div>
          )
        )}
      </div>
      <div className="flex flex-row justify-between w-full gap-4">
        <div className="flex flex-col w-1/2 gap-4">
          <div>
            <FormFieldControlled
              onChange={(value) => {
                setEmailBody({
                  ...emailBody,
                  subject: value.target.value,
                });
              }}
              value={emailBody.subject}
              label="Subject"
            />
          </div>
          <div className="mb-[46px]">
            <ReactQuill
              theme="snow"
              value={emailBody.body}
              onChange={(text: string) =>
                setEmailBody((prev) => ({
                  ...prev,
                  body: text,
                }))
              }
              style={{ height: "400px", width: "100%" }}
            />
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => handleSendEmail()}
            >
              Send
            </Button>
            <Dialog
              open={openConfirmDialog}
              onOpenChange={setOpenConfirmDialog}
            >
              <DialogContent
                className="max-w-80"
                onAbort={() => console.log("nope")}
                onDragExit={() => console.log("exit")}
              >
                <DialogHeader>
                  <DialogTitle>Confirm Division Email</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                  <div className="flex flex-col">
                    <Body1>
                      This will send an email to{" "}
                      {captainsOnly ? selectedTeams.length : numberOfPlayers}{" "}
                      people.
                    </Body1>
                  </div>
                </DialogDescription>
                <div className="flex flex-row gap-2">
                  <DialogClose asChild>
                    <Button
                      className="w-full"
                      variant={"primary"}
                      onClick={() => {
                        setOpenConfirmDialog(false);
                        sendEmail();
                      }}
                    >
                      Confirm
                    </Button>
                  </DialogClose>
                  <DialogClose asChild>
                    <Button
                      className="w-full"
                      variant="negative"
                    >
                      Cancel
                    </Button>
                  </DialogClose>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className="flex flex-col w-1/2 gap-4 ">
          <div className="flex flex-col">
            <Headline2Variable>
              Email Variables (click to copy)
            </Headline2Variable>
            <div className="flex flex-col">
              <div className="flex flex-row gap-2">
                <Body1 className="w-32">Day of week:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%day_of_week%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="bg-info-90 w-fit">%%day_of_week%%</Body1>
              </div>
              <div className="flex flex-row gap-2">
                <Body1 className="w-32">Sport:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%sport%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className=" bg-info-90 w-fit">%%sport%%</Body1>
              </div>
              <div className="flex flex-row gap-2">
                <Body1 className="w-32">First Name:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%first_name%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className=" bg-info-90 w-fit">%%first_name%%</Body1>
              </div>
              <div className="flex flex-row gap-2">
                <Body1 className="w-32">Team Name:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%team_name%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className=" bg-info-90 w-fit">%%team_name%%</Body1>
              </div>
              <div className="flex flex-row gap-2">
                <Body1 className="w-32">Button:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%button_link_text%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className=" bg-info-90 w-fit">
                  %%button_link_text%%
                </Body1>
                <Body1>%%button_https://jamsports.com_Home%%</Body1>
              </div>
            </div>
          </div>
          <div className="max-w-[600px]">
            <iframe
              ref={iframeRef}
              title="Email Preview"
              style={{ width: "100%", height: "600px", border: "none" }}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmailToolDivisions;
