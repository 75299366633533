export const footer = `</td>
<td style="width: 20px">&nbsp;</td>
</tr>
<tr>
<td
  colspan="3"
  style="height: 20px; width: 20px"
>
  &nbsp;
</td>
</tr>
</table>
<!-- Line -->
<table
style="
width: 100%;
border-collapse: collapse;
background-color: #ffffff;
"
class="darkmode-body"
>
<tr>
<td
  colspan="3"
  style="height: 20px; width: 20px; mso-hide: all"
>
  &nbsp;
</td>
</tr>
<tr>
<td style="width: 20px; height: 20px">&nbsp;</td>
<td style="border-top: 1px solid grey">&nbsp;</td>
<td style="width: 20px; height: 20px">&nbsp;</td>
</tr>
</table>
<!-- Footer -->
<table
style="
width: 100%;
border-collapse: collapse;
background-color: #ffffff;
"
class="darkmode-body"
>
<tr>
<td
  colspan="3"
  style="height: 20px; width: 20px"
>
  &nbsp;
</td>
</tr>
<tr>
<td style="width: 20px">&nbsp;</td>
<td
  style="text-align: center; line-height: 24px; font-size: 12px"
>
  <!-- Add your footer content here -->
  Phone: <a href="tel:4167814263">416-781-GAME</a> (4263) | Email:
  <a href="mailto:play@jamsports.com">play@jamsports.com</a>
  | <a href="http://jamsports.com">Website</a><br />
  245 Eglinton Avenue East - Suite #420. Toronto, ON. M4P 3B7
</td>
<td style="width: 20px">&nbsp;</td>
</tr>
<tr>
<td
  colspan="3"
  style="height: 20px"
>
  &nbsp;
</td>
</tr>
</table>
</td>
</tr>
</table>
</body>
</html>
`;

export const marketingFooter = ({
  url,
  email,
}: {
  url: string;
  email: string;
}): string => {
  return `</td>
<td style="width: 20px">&nbsp;</td>
</tr>
<tr>
<td
  colspan="3"
  style="height: 20px; width: 20px"
>
  &nbsp;
</td>
</tr>
</table>
<!-- Line -->
<table
style="
width: 100%;
border-collapse: collapse;
background-color: #ffffff;
"
class="darkmode-body"
>
<tr>
<td
  colspan="3"
  style="height: 20px; width: 20px; mso-hide: all"
>
  &nbsp;
</td>
</tr>
<tr>
<td style="width: 20px; height: 20px">&nbsp;</td>
<td style="border-top: 1px solid grey">&nbsp;</td>
<td style="width: 20px; height: 20px">&nbsp;</td>
</tr>
</table>
<!-- Footer -->
<table
style="
width: 100%;
border-collapse: collapse;
background-color: #ffffff;
"
class="darkmode-body"
>
<tr>
<td
  colspan="3"
  style="height: 20px; width: 20px"
>
  &nbsp;
</td>
</tr>
<tr>
<td style="width: 20px">&nbsp;</td>
<td
  style="text-align: center; line-height: 24px; font-size: 12px"
>
  <!-- Add your footer content here -->
  Phone: <a href="tel:4167814263">416-781-GAME</a> (4263) | Email:
  <a href="mailto:play@jamsports.com">play@jamsports.com</a>
  | <a href="http://jamsports.com">Website</a><br />
  245 Eglinton Avenue East - Suite #420. Toronto, ON. M4P 3B7
</td>
<td style="width: 20px">&nbsp;</td>
</tr>
<tr>
<td style="width: 20px">&nbsp;</td>
<td
  style="text-align: center; line-height: 24px; font-size: 12px"
>
  <!-- Add your footer content here -->
  We sent this email to you at ${email} because you previously opted in to receive 
  emails from JAM Sports. If you'd prefer not to receive marketing emails from us, 
  please <a target="_blank" href="${url}" style="text-decoration:underline">unsubscribe here.</a>
  <br />
  Please note you will always receive schedule updates and league specific emails. 
</td>
<td style="width: 20px">&nbsp;</td>
</tr>
<tr>
<td
  colspan="3"
  style="height: 20px"
>
  &nbsp;
</td>
</tr>
</table>
</td>
</tr>
</table>
</body>
</html>
`;
};
